var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid mt-5"},[_c('div',[_c('card',{staticClass:"no-border-card",attrs:{"body-classes":"px-0 pb-1","footer-classes":"pb-2"}},[_c('template',{slot:"header"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-8"},[_c('h3',{staticClass:"mb-0"},[_c('span',{staticClass:"text-before-button"},[_vm._v(_vm._s(_vm.resellerProduct.name))]),(
                  _vm.$currentUserCan(_vm.$permissions.PERM_EDIT_RESELLER_PRODUCTS)
                )?_c('base-button',{attrs:{"type":"primary","icon":"","size":"sm"},on:{"click":function($event){return _vm.editResellerProduct()}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-edit"})])]):_vm._e(),(
                  _vm.$currentUserCan(_vm.$permissions.PERM_DELETE_RESELLER_PRODUCTS)
                )?_c('base-button',{attrs:{"type":"danger","icon":"","size":"sm"},on:{"click":function($event){return _vm.deleteResellerProduct()}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-trash"})])]):_vm._e()],1)]),_c('div',{staticClass:"col-4 text-right"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.goBack()}}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.RETURN_TO_LIST"))+" ")]),_c('notification-subscription',{attrs:{"objectType":'reseller-products',"objectId":_vm.resellerProduct.id,"events":{
                UPDATE: _vm.$t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: _vm.$t('NOTIFICATIONS.EVENT_DELETE'),
              }}})],1)])]),_c('div',[_c('div',{staticClass:"col-12 justify-content-center justify-content-sm-between flex-wrap tabs-wrapper"},[_c('tabs',{staticClass:"flex-column flex-md-row",attrs:{"fill":"","tabNavWrapperClasses":"nav-wrapper","tabNavClasses":"nav nav-pills nav-fill","value":"global"}},[_c('card',{attrs:{"shadow":""}},[_c('tab-pane',{attrs:{"title":"global","id":"1","active":true}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"ni ni-cloud-upload-96"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.GLOBAL"))+" ")]),_c('reseller-product-view-global',{attrs:{"resellerProduct":_vm.resellerProduct}})],1),_c('tab-pane',{attrs:{"title":"gallery","id":"2"}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"ni ni-cloud-upload-96"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.GALLERY"))+" ")]),_c('reseller-product-view-gallery',{attrs:{"resellerProduct":_vm.resellerProduct}})],1),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOGS))?_c('tab-pane',{attrs:{"title":"logs","id":"999"}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"fa fa-file"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.LOGS"))+" ")]),_c('resellerProduct-view-logs',{attrs:{"resellerProduct":_vm.resellerProduct}})],1):_vm._e()],1)],1)],1)])],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }