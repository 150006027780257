<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <span class="display-4 col-12">{{ resellerProduct.name }}</span>
    </div>

    <div class="row">
      <div class="col-9">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.NAME") }}</dt>
          <dd class="col-sm-8">
            {{ resellerProduct.name }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.EXCERPT") }}</dt>
          <dd class="col-sm-8">
            <div v-html="resellerProduct.excerpt"></div>
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.PRICE") }}</dt>
          <dd class="col-sm-8">
            {{ $formatCurrency(resellerProduct.price) }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.TAXES") }}</dt>
          <dd class="col-sm-8">
            <dl
              class="row"
              v-for="(tax, key) in resellerProduct.taxes"
              :key="key"
            >
              <dt class="col-sm-4">{{ tax.name }}</dt>
              <dd class="col-sm-8" v-if="tax.type === TAX_TYPE_PERCENTAGE">
                {{ tax.value }} %
              </dd>
              <dd class="col-sm-8" v-if="tax.type === TAX_TYPE_AMOUNT">
                {{ $formatCurrency(tax.value) }}
              </dd>
            </dl>
          </dd>
        </dl>
        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.RESELLER") }}</dt>
          <dd class="col-sm-8">
            <reseller :reseller="resellerProduct.reseller" />
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8" v-if="resellerProduct.created_at">
            {{ resellerProduct.created_at | moment("LLLL") }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd class="col-sm-8" v-if="resellerProduct.updated_at">
            {{ resellerProduct.updated_at | moment("LLLL") }}
          </dd>
        </dl>
      </div>
      <div class="col-3">
        <img
          v-if="resellerProduct.picture"
          :src="`${resellerProduct.picture}`"
          class="argon-image mb-2"
          style="width: 100%"
        />
        <img v-else src="/img/placeholder.jpg" class="argon-image mb-2" />
      </div>
    </div>
  </div>
</template>

<script>
import { TAX_TYPE_PERCENTAGE, TAX_TYPE_AMOUNT } from "@/constants/taxes";

export default {
  name: "reseller-product-view-global",

  components: {},

  props: ["resellerProduct"],

  data() {
    return {
      TAX_TYPE_AMOUNT: TAX_TYPE_AMOUNT,
      TAX_TYPE_PERCENTAGE: TAX_TYPE_PERCENTAGE,
    };
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    resellerProduct(resellerProduct) {},
  },
};
</script>
